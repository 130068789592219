<template>
  <div v-loading.fullscreen.lock="loading">
    <el-row justify="space-between">
      <el-col :sm="24" :md="12" :lg="12">
        <el-button type="primary" @click="openModal">
          Create new Broker
        </el-button>
      </el-col>
    </el-row>
    <el-row :gutter="20" align="middle" class="align-items-center">
      <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
        <p class="demonstration">Phone Number</p>
        <el-input v-model="phone_number" size="large" type="number" />
      </el-col>

      <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
        <p class="demonstration">Name</p>
        <el-input v-model="name" size="large"/>
      </el-col>

      <el-col :xs="24" :sm="24" :md="5" :lg="5" :xl="5">
        <p class="demonstration">Start date</p>
        <el-date-picker
          v-model="start_date"
          type="date"
          class="w-100"
          placeholder="Pick a day"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          size="large"
        />
      </el-col>
      <el-col :xs="24" :sm="24" :md="5" :lg="5" :xl="5">
        <p class="demonstration">End date</p>
        <el-date-picker
          v-model="end_date"
          type="date"
          class="w-100"
          placeholder="Pick a day"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          size="large"
        />
      </el-col>
      <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4" class="mt-40">
        <el-button type="primary" size="large" @click="getBrokers" round
          >Filter</el-button
        >
        <el-button type="danger" size="large" @click="clear" round
          >Clear</el-button
        >
      </el-col>
    </el-row>
    <br />

    <el-table :data="filteredList" border style="width: 100%">
      <el-table-column prop="profile_image" label="Image">
        <template #default="scope">
          <div class="demo-type">
            <el-avatar v-if="scope.row.profile_image" :size="60">
              <img :src="userPhoto(scope.row.profile_image)" />
            </el-avatar>
            <el-avatar v-else :size="60">
              <img
                src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"
              />
            </el-avatar>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="fullname" label="Name" />
      <el-table-column prop="email" label="Email" />
      <el-table-column
        header-align="center"
        prop="phone_number"
        label="Phone"
        v-if="
          authUser.user_type == 'agent-pharmacy' ||
          authUser.user_type == 'cashier' ||
          authUser.user_type == 'executive'
        "
      >
        <template #default="scope">
          {{ scope.row.phone_number }}
        </template>
      </el-table-column>
      <el-table-column label="Created At" prop="created_at" align="center">
      </el-table-column>

      <el-table-column
        prop="actions"
        header-align="center"
        label="Actions"
        width="200"
      >
        <!-- <template #default="scope">
          <router-link class="m-5" :to="`/brokers/${scope.row.id}`" v-if="checkUsrPermissions('User profile')">
            <el-button type="success" circle>
              <el-icon>
                <View/>
              </el-icon>
            </el-button>
          </router-link>
          <router-link class="m-5" :to="`/appointments?id=${scope.row.id}&name=${scope.row.fullname}`">
            <el-button type="info" circle>
              <el-icon>
                <Checked/>
              </el-icon>
            </el-button>
          </router-link>
          <el-button type="danger"  circle @click="craeteCall(scope.row)">
              <el-icon><PhoneFilled /></el-icon>
          </el-button>
          <el-button type="warning" class="m-5" v-if="is_message" circle @click="sendMessage(scope.row)">
            <el-icon><ChatDotRound /></el-icon>
          </el-button>

        </template> -->
      </el-table-column>
    </el-table>
    <br />

    <el-pagination
      v-model:current-page="currentPage"
      :page-size="per_page"
      layout="total, prev, pager, next"
      :total="total"
      @current-change="getBrokers"
    />
    <div>
      <AddBrokerModal
        @getBrokers="getBrokers"
        @modalClosed="is_change_modal_open = false"
        v-if="is_change_modal_open"
      />
    </div>
    <!-- <CallLogModal v-if="call_log_dailog" :patient_id="patient_log.id"  @modalClosed="call_log_dailog=false"/> -->
    <!-- <MessageModal v-if="message_dailog" :patient_id="patient_log.id"  @modalClosed="message_dailog=false"/> -->
  </div>
</template>

<script>
import axios from "axios";
import { CommonFunction } from "@/mixins/CommonFunction";
import { mapGetters } from "vuex";
import { CenterMixins } from "@/mixins/CenterMixins";
import AddBrokerModal from "../../components/modal/AddBrokerModal";
// import CallLogModal from '../../components/modal/CallLogModal.vue';
// import MessageModal from '../../components/modal/MessageModal';
export default {
  name: "Brokers",
  components: {
    AddBrokerModal,
  },
  props: {
    is_message: {
      type: Boolean,
      required: false,
    },
  },
  mixins: [CommonFunction, CenterMixins],
  data() {
    return {
      tableData: [],
      dialogFormVisible: false,
      is_change_modal_open: false,
      loading: false,
      total: 0,
      currentPage: 0,
      per_page: 20,
      start_date: null,
      end_date: null,
      name: "",
      phone_number: "",
      show_my_patient: "",

      brokers: [],
      patient_log: {},
      call_log_dailog: false,
      message_dailog: false,
    };
  },
  created() {
    if (this.$route.query.my_paitent) {
      this.show_my_patient = this.$route.query.my_paitent;
    }
    this.getBrokers();

    setTimeout(()=> {
      if(this.$route.query.create) {
        this.openModal();
      }
    },800)
  },
  computed: {
    ...mapGetters("auth", ["authUser"]),
    filteredList() {
      return this.tableData.filter(
        (data) =>
          !this.search ||
          data.fullname.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  methods: {
    getBrokers(val) {
      if (val) {
        const offset = val - 1;
        var correctValue = offset * this.per_page;
      }
      this.loading = true;
      const url = `/api/v2/partner/brokers?limit=${this.per_page}&offset=${
        correctValue || 0
      }${this.start_date ? `&start_date=${this.start_date}` : ""}${
        this.end_date ? `&end_date=${this.end_date}` : ""
      }${this.name ? `&name=${this.name}` : ""}${
        this.phone_number ? `&phone_number=${this.phone_number}` : ""
      }`;
      axios.get(url).then((response) => {
        this.tableData = response.data.data;
        this.total = response.data.total_count;
        this.loading = false;
      });
    },
    openModal() {
      this.is_change_modal_open = true;
    },
    clear() {
      this.start_date = null;
      this.end_date = null;
      this.q = "";
      this.getBrokers();
    },
    userPhoto(img) {
      if (img) {
        return `${process.env.VUE_APP_API_URL}${img}`;
      }
    },
  },
  title: "Brokers",
};
</script>

<style scoped></style>
