<template>
  <el-form
    label-position="top"
    label-width="100px"
    :model="form"
    :rules="rules"
    ref="form"
  >
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item label="First name" prop="first_name">
          <el-input v-model="form.first_name" />
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="Last name" prop="last_name">
          <el-input v-model="form.last_name" />
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="Phone number" prop="phone_number">
          <el-input v-model="form.phone_number" type="number">
            <template #prepend>+88</template>
          </el-input>
        </el-form-item>
      </el-col>

      <el-col :span="12">
        <el-form-item label="Date of Birth" prop="dob" class="w-100">
          <div class="block w-100">
            <el-date-picker
              class="w-100"
              v-model="form.dob"
              type="date"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
            />
          </div>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="Gender" prop="gender">
          <el-select v-model="form.gender" class="w-100">
            <el-option label="Male" value="male" />
            <el-option label="Female" value="female" />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="Blood group">
          <el-select v-model="form.blood_group" class="w-100">
            <el-option label="A+" value="A+" />
            <el-option label="B+" value="B+" />
            <el-option label="O+" value="O+" />
            <el-option label="AB+" value="AB+" />
            <el-option label="A-" value="A-" />
            <el-option label="B-" value="B-" />
            <el-option label="O-" value="O-" />
            <el-option label="AB-" value="AB-" />
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :span="12">
        <el-form-item label="Profile Photo">
          <input type="file" ref="profile_img" id="profile_img" />
        </el-form-item>
        <img
          :src="userPhoto(broker.image)"
          v-if="broker"
          alt=""
          style="height: 100px"
        />
      </el-col>
    </el-row>

    <span class="dialog-footer">
      <el-button @click="closeModal">Cancel</el-button>
      <el-button type="primary" @click="submitForm('form')">
        Confirm
      </el-button>
    </span>
  </el-form>
</template>

<script>
import axios from "axios";
import store from "../../store/index";

export default {
  name: "PatientForm",
  props: {
    broker: {
      type: Object,
      required: false,
    },
    phone_number: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      form: {
        id: "",
        first_name: "",
        last_name: "",
        gender: "",
        location: "",
        phone_number: "",
        blood_group: "",
        dob: "",
      },
      dialogFormVisible: false,
      medical_services: [],
      chronic_diseases: [],
      locations: [],
      rules: {
        first_name: [
          {
            required: true,
            message: "Please input  name",
          },
        ],
        phone_number: [
          {
            required: true,
            min: 11,
            message: "Please input  phone number",
          },
        ],
        gender: [
          {
            required: true,
            message: "Please input  gender",
          },
        ],
        dob: [
          {
            required: false,
            message: "Please input  Date of birth",
          },
        ],
      },
      tempDocument: [],
      preview_img: null,
    };
  },
  created() {
    if (this.broker) {
      this.preview_img = this.broker.image;
      const broker = { ...this.broker };
      const real_number = broker.phone_number.slice(0);
      this.form = { ...this.broker };
      this.form.phone_number = parseInt(real_number);
    }
    if (this.phone_number) {
      this.form.phone_number = this.phone_number;
    }
  },

  methods: {
    submitForm(formEl) {
      this.$refs[formEl].validate((valid) => {
        if (valid) {
          const profile_img = this.$refs.profile_img.files
            ? this.$refs.profile_img.files[0]
            : "";
          const form = new FormData();

          form.append("first_name", this.form.first_name);

          form.append(
            "last_name",
            this.form.last_name ? this.form.last_name : ""
          );

          form.append("gender", this.form.gender);

          if (!this.form.id) {
            form.append("phone_number", `+88${this.form.phone_number}`);
          }

          form.append("blood_group", this.form.blood_group);
          form.append("dob", this.form.dob);

          form.append("user_type", this.form.user_type);

          if (profile_img && !this.form.id) {
            form.append("image", profile_img);
          } else if (this.form.id && profile_img) {
            form.append("profile_img", profile_img);
          }

          let url = "";
          if (this.form.id) {
            url = `/api/v2/partner/brokers/${this.form.id}?_method=put`;
          } else {
            url = "/api/v2/partner/brokers";
          }
          axios.post(url, form).then((response) => {
            if (response.data.status_code == 200) {
              this.$emit("dialogClose", (this.dialogFormVisible = false));
              this.$notify({
                title: "Success",
                message: "Succesfully Save",
                type: "success",
                duration: 2000,
              });
              if (this.broker.id) {
                this.form = response.data.data.user;
              } else {
                this.form = {};
              }
            } else {
              this.$notify({
                title: "Failed",
                message: response.data.message,
                type: "danger",
                duration: 2000,
              });
            }
          });
        } else {
          return true;
        }
      });
    },

    closeModal() {
      if (this.form.id) {
        this.$router.go(-1);
      } else {
        this.$emit("dialogClose", (this.dialogFormVisible = false));
      }
    },
    fileChange(file) {
      this.tempDocument.push(file.raw);
    },
    userPhoto(img) {
      if (img) {
        return `${process.env.VUE_APP_API_URL}${img}`;
      }
    },
  },
};
</script>

<style>
.w-100 {
  width: 100%;
}
</style>
