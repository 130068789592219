<template>
  <div>
    <!--  modal  -->
    <el-dialog v-model="dialogFormVisible" title="Create new Broker" class="appointment-status-modal" top="1vh">
      <BrokerForm :is_zero_club_foot="is_zero_club_foot" @dialogClose="dialogClose" @selectedPatient="selectedPatient"/>
    </el-dialog>
  </div>
</template>

<script>
import BrokerForm from '../form/BrokerForm';

export default {
  name: 'AddBrokerModal',
  components: {
    BrokerForm,
  },
  data() {
    return {
      dialogFormVisible: false,
    };
  },
  created() {
    this.dialogFormVisible = true;
  },
  methods: {
    dialogClose() {
      this.dialogFormVisible = false;
    },
    selectedPatient(value) {
      this.$emit('selectedPatient', value);
    },
  },
  watch: {
    dialogFormVisible(oldValue, newValue) {
      if (!oldValue) {
        this.$emit('modalClosed');
        this.$emit('getBrokers');
      }
    },
  },
};
</script>

<style>
.w-100 {
  width: 100% !important;
}
</style>
